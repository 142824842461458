import { CssBaseline, ThemeProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import theme from "./theme";

const useUiCache = () => createCache({ key: "css" });

const UiKitProvider = ({ children }: { children: React.ReactNode }) => {
  const cache = useUiCache();
  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default UiKitProvider;
